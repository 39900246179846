
@tailwind base;

@tailwind components;

@tailwindcss/forms;
@tailwindcss/typography;
@tailwindcss/aspect-ratio;



@tailwind utilities;

h3,p,li {
  font-family: 'Teko', sans-serif;
  font-size: 1.5rem;
  line-height: 2rem;
}
[x-cloak] {
  display: none;
}
.border-triangle {
    border-color: #fff #FED301 #FED301 #FED301;
    border-style: solid;
    border-width: 100px 630px 0px 630px;
    height: 0;
    width: 100%;
    display: flex;
    flex-grow: 1;
}
.border-triangle2 {
    border-color: #FED301 #fff #fff #fff;
    border-style: solid;
    border-width: 100px 630px 0px 630px;
    height: 0;
    width: 100%;
    display: flex;
    flex-grow: 1;
}

/* Shine */
.hover14 figure {
	position: relative;
}
.hover14 figure::before {
	position: absolute;
	top: 0;
	left: -75%;
	z-index: 2;
	display: block;
	content: '';
	width: 50%;
	height: 100%;
	background: -webkit-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,.3) 100%);
	background: linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(255,255,255,.3) 100%);
	-webkit-transform: skewX(-25deg);
	transform: skewX(-25deg);
}
.hover14 figure:hover::before {
	-webkit-animation: shine .75s;
	animation: shine .75s;
}
@-webkit-keyframes shine {
	100% {
		left: 125%;
	}
}
@keyframes shine {
	100% {
		left: 125%;
	}
}

ul {
  list-style-image: url('../images/img_30px.png');
  display: inline;
}

.image1 {
    content: url("../images/c89a2409-d461-4784-a29b-ccf039e36b08_aacc4701-a791-41fb-ac8d-80acce062e28.jpg")
  }

  .image2 {
    content: url("../images/c89a2409-d461-4784-a29b-ccf039e36b08_aacc4701-a791-41fb-ac8d-80acce062e28.jpg")
  }

  .image3 {
    content: url("../images/c89a2409-d461-4784-a29b-ccf039e36b08_aacc4701-a791-41fb-ac8d-80acce062e28.jpg")
  }

  .image4 {
    content: url("../images/c89a2409-d461-4784-a29b-ccf039e36b08_aacc4701-a791-41fb-ac8d-80acce062e28.jpg")
  }

  .image5 {
    content: url("../images/c89a2409-d461-4784-a29b-ccf039e36b08_aacc4701-a791-41fb-ac8d-80acce062e28.jpg")
  }

#map-ams > .leaflet-pane{
  z-index: 0;
}
.ams-map-icon {
    width: 30px !important;
    height: 30px !important;
    background-image: url(../images/mapicon.png);
    background-size: cover;
}

.irs--ams .irs-from,.irs--ams .irs-to, .irs--ams .irs-single {
  background-color: #1f2937 !important;
}
 
.irs--ams .irs-from:before, .irs--ams .irs-to:before, .irs--ams .irs-single:before{
  border-top-color: #1f2937 !important;
}
.irs--ams .irs-handle{
  border: 4px solid #1f2937 !important;
  background-color: #1f2937 !important;
}


@media screen and (max-width:768px){
  .border-triangle, .border-triangle2 {
      border-width: 100px 240px 0px 240px;
  }
}

@media screen and (min-width:1536px){
  .border-triangle, .border-triangle2 {
      border-width: 150px 945px 0px 945px;
  }
}
@media screen and (min-width:2560px){
  .border-triangle, .border-triangle2 {
      border-width: 150px 1280px 0px 1280px;
  }
}

#specifics tbody tr:nth-child(odd) {
  @apply bg-gold-300;
}
#specifics tbody tr:nth-child(even) {
  @apply bg-white;
}
  /* bg-gold-300 */
  /* bg-white */
