.irs--ams {
    height: 50px;
}
.irs--ams.irs-with-grid {
    height: 65px;
}
.irs--ams .irs-line {
    top: 36px;
    height: 4px;
    background-color: #dee4ec;
    border-radius: 4px;
}
.irs--ams .irs-bar {
    top: 36px;
    height: 4px;
    background-color: #000000;
}
.irs--ams .irs-bar--single {
    border-radius: 4px 0 0 4px;
}
.irs--ams .irs-shadow {
    height: 4px;
    bottom: 21px;
    background-color: rgba(222, 228, 236, 0.5);
}
.irs--ams .irs-handle {
    top: 26px;
    width: 24px;
    height: 24px;
    border: 4px solid #006cfa;
    background-color: white;
    border-radius: 24px;
    box-shadow: 0 1px 3px rgba(0, 0, 255, 0.3);
}
.irs--ams .irs-handle.state_hover,
.irs--ams .irs-handle:hover {
    background-color: #f0f6ff;
}
.irs--ams .irs-min,
.irs--ams .irs-max {
    color: #333;
    font-size: 14px;
    line-height: 1;
    top: 0;
    padding: 3px 5px;
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 4px;
}
.irs--ams .irs-from,
.irs--ams .irs-to,
.irs--ams .irs-single {
    font-size: 14px;
    line-height: 1;
    text-shadow: none;
    padding: 3px 5px;
    background-color: #006cfa;
    color: white;
    border-radius: 4px;
}
.irs--ams .irs-from:before,
.irs--ams .irs-to:before,
.irs--ams .irs-single:before {
    position: absolute;
    display: block;
    content: "";
    bottom: -6px;
    left: 50%;
    width: 0;
    height: 0;
    margin-left: -3px;
    overflow: hidden;
    border: 3px solid transparent;
    border-top-color: #006cfa;
}
.irs--ams .irs-grid {
    height: 25px;
}
.irs--ams .irs-grid-pol {
    background-color: #dedede;
}
.irs--ams .irs-grid-text {
    color: silver;
    font-size: 13px;
}